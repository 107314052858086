import React from "react"
import { Link } from "gatsby"

import Links from "./Links"

export default function Navbar({ toggle }) {
  return (
    <nav className="navbar">
      <div className="wrapper">
        <div className="logo">
          <Link to="/">WIĘCEJ NIŻ BLOG</Link>
        </div>
        <Links styleClass="navbar-links" />
        <button
          className="hamburger"
          onClick={toggle}
          aria-label="Otwórz menu mobilne"
        >
          <svg
            fill="none"
            stroke="#FFFFFF"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
    </nav>
  )
}

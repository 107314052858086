import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper">
        <p>
          &copy; <Link to="/">wiecejnizblog.pl</Link> {new Date().getFullYear()}
          : Wszelkie prawa zastrzeżone.
        </p>
      </div>
    </footer>
  )
}

import React from "react"

import Links from "./Links"

export default function Sidebar({ isActive, toggle }) {
  return (
    <aside className={`sidebar ${isActive ? "active" : ""}`}>
      <Links styleClass="sidebar-links" />
      <button
        className="hamburger"
        onClick={toggle}
        aria-label="Zamknij menu mobilne"
      >
        <svg
          fill="none"
          stroke="#FFFFFF"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </aside>
  )
}

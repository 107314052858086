import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        twitterUsername
        image
        siteUrl
      }
    }
  }
`
const SEO = ({ title, description, articleUrl, imageUrl }) => {
  const { site } = useStaticQuery(query)
  const {
    siteTitle,
    siteDesc,
    twitterUsername,
    image,
    siteUrl,
  } = site.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "pl" }} title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={`${siteUrl}${imageUrl || image}`} />
      {/* twitter cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title || siteTitle} />
      <meta name="twitter:description" content={description || siteDesc} />
      <meta name="twitter:image" content={`${siteUrl}${imageUrl || image}`} />
      {/* facebook cards */}
      <meta property="og:title" content={title || siteTitle} />
      <meta property="og:description" content={description || siteDesc} />
      <meta property="og:image" content={`${siteUrl}${imageUrl || image}`} />
      <meta property="og:url" content={`${siteUrl}/${articleUrl || ""}`} />
    </Helmet>
  )
}

export default SEO

import React from "react"
import { Link } from "gatsby"

export default function Links({ styleClass }) {
  return (
    <div className={styleClass}>
      <div className="main-links">
        <Link to="/barca">Barca</Link>
        <Link to="/inne">Inne</Link>
      </div>
      <div className="other-links">
        <a
          href="https://www.facebook.com/wiecejnizblog"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/icon-facebook.png"
            width="24"
            height="24"
            alt="facebook icon"
          ></img>
        </a>
        <a
          href="https://www.twitter.com/wiecejnizblog"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/icon-twitter.png"
            width="24"
            height="24"
            alt="twitter icon"
          ></img>
        </a>
        <a
          href="https://www.instagram.com/wiecejnizblog"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/icon-instagram.png"
            width="24"
            height="24"
            alt="instagram icon"
          ></img>
        </a>
      </div>
    </div>
  )
}

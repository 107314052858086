import React, { useState } from "react"

import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import "../styles/global.css"

export default function Layout({ children }) {
  const [isActive, setIsActive] = useState(false)

  const toggle = () => {
    setIsActive(!isActive)
  }
  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isActive={isActive} toggle={toggle} />
      <main className="content">{children}</main>
      <Footer />
    </>
  )
}
